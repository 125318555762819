.header {
  margin-top: 40px;  
  margin-left: 40px;
  margin-right: 40px;
}

.footer {
  border-top: 1px solid #ddd;
  padding-top:40px;
  margin-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
}

.navigation-link {
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  margin-left:20px;
  margin-right: 20px;
}

.flex-center {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.block-container {
  height: calc(100vh - 120px);
  justify-content:center;
  align-items: center;
}

.home-image {
  width: 100%;
  padding:20px;
}

.main-text {
  font-size: 48px;
  font-weight: 700;
  line-height: 3rem;
}

.main-subtext {
  margin-top: 40px;
  font-size: 14px;
}

.main-subtext2 {
  font-size: 14px;
}

.main-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main-button {
  border: 1px solid #000;
  border-radius: 10px;
  text-align: center;
  padding-left: 20px;
  padding-right:20px;
  padding-top:10px;
  padding-bottom:10px;
  margin-left:10px;
  margin-right:10px;
  text-decoration: none;
  color:#000;
}

.main-button:hover {
  background-color: #eee;
  text-decoration: none;
  color:#000;
}

.social {
  border-top: 1px solid #ddd;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
}

.social-icon {
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  color:#000;
}

.processes {
  margin-top: 40px;
}

.process {
  margin-top:30px;
  margin-bottom: 30px;
}

.process:hover {
  cursor: pointer;
}

.process-image {
  width: 200px;
  height:200px;
}

.process-heading {
  margin-top: 10px;
  font-size: 32px;
}